import type { ReactNode } from "react";
import type { Toast } from "react-hot-toast";
import { toast } from "react-hot-toast";

import { Cross2Icon } from "@radix-ui/react-icons";

import { Text } from "../ui/text";

type Props = {
  message: string;
  t: Toast;
  children?: ReactNode;
  childrenClassname?: string;
};
const ToastSuccess = ({ message, t, children, childrenClassname }: Props) => {
  return (
    <div className="flex max-w-[312px] flex-col rounded-lg border-2 border-success bg-success-lite p-4 shadow-lg md:max-w-[363px]">
      <div className="flex justify-between gap-4">
        <Text className="text-sm font-normal">{message}</Text>

        <button onClick={() => toast.remove(t.id)} className="self-start">
          <Cross2Icon width={24} height={24} />
        </button>
      </div>
      <div className={childrenClassname}>{children}</div>
    </div>
  );
};

export default ToastSuccess;
